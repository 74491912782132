import { object, string, number } from 'yup'

export const ProfileUpdateValidationSchema = object({
    firstName: string().required('Enter first name'),
    lastName: string().required('Enter last name'),
    bio: string().max(255, 'Max characters limit is exceeded').optional(),
    grade: number()
        .min(1, 'Should be higher than or equal to 1')
        .max(5, 'Should be less than or equal to 5')
        .test('grade', 'Enter grade', function (value) {
            const { nonStudent } = this.parent

            if (nonStudent) {
                return true
            }

            return !!value
        })
        .required('Your should specify your grade'),
})
