import React from 'react'

import { Box, Stack } from '@mui/material'
import { SnackbarProvider } from 'notistack'

import { withAuth } from 'hocs'
import { renderRoutesByRole } from 'utils/roles.utils'
import {
    ReactiveDialog,
    ReactiveDrawer,
    NotificationWidget,
    Notification,
} from 'components/common'
import { GlobalError, Header, Loader } from 'components/layout'
import PostSignUpPage from 'components/pages/user/PostSignUpPage'
import { appRoutes } from 'routes'
import { useVersionStamp } from 'hooks/common'
import { useConfig } from 'hooks/config'
import { useUser } from 'hooks/user'

const App = () => {
    useVersionStamp()

    const { user, loading: userLoading, error } = useUser()
    const { loading: configLoading } = useConfig()

    if (userLoading || configLoading) {
        return <Loader />
    }

    if (error) {
        return <GlobalError />
    }

    if (!user?.school || !user?.zipCode || !user?.grade) {
        return <PostSignUpPage />
    }

    return (
        <Box
            sx={{
                background: '#fff',
                display: 'flex',
                justifyContent: 'stretch',
                height: '100vh',
            }}
        >
            <ReactiveDialog />
            <ReactiveDrawer />
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Header />
                {user && (
                    <SnackbarProvider
                        classes={{
                            containerRoot: 'snackbar-container-root',
                            root: 'snackbar-root',
                        }}
                        maxSnack={1}
                        autoHideDuration={3000}
                        hideIconVariant
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        Components={{
                            notification: Notification,
                        }}
                    >
                        <NotificationWidget />
                    </SnackbarProvider>
                )}
                {renderRoutesByRole(appRoutes, user?.role)}
            </Stack>
        </Box>
    )
}

export default withAuth(App)
