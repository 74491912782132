import React from 'react'

import { useNavigate } from 'react-router-dom'

import { PostSignUpForm } from 'components/forms'
import { AuthorizationLayout } from 'components/layout'
import { userPaths } from 'routes'
import { useSchools } from '../../../../hooks/school'

const PostSignUpPage = (): JSX.Element => {
    const navigate = useNavigate()

    const { schools } = useSchools()

    const onSuccess = (): void => {
        // navigate(`../${userPaths.homepage}`)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = window.location.href
    }

    return (
        <AuthorizationLayout title="Welcome">
            <PostSignUpForm onSuccess={onSuccess} schools={schools} />
        </AuthorizationLayout>
    )
}

export default PostSignUpPage
