import { Box, Stack, Typography } from '@mui/material'

import { Image, Tag } from 'components/common'
import noImage from 'assets/images/event-no-image.jpg'
import { EventType } from 'api/generated'
import { formatDate, formatLocation } from 'utils/format.utils'

interface EventCardProps {
    data: EventType
    onClick: (id: string) => void
}

const EventCard = ({ data, onClick }: EventCardProps) => {
    return (
        <Stack
            gap="16px"
            sx={{
                width: '100%',
                padding: '12px',
                borderRadius: '9px',
                border: '1px solid #E5E7EB',
                cursor: 'pointer',
                '&:hover': {
                    border: '1px solid #8c8c8c',
                },
            }}
            onClick={() => onClick(data.id)}
        >
            <Box
                sx={{
                    position: 'relative',
                    maxWidth: '520px',
                    maxHeight: '290px',
                    overflow: 'hidden',
                }}
            >
                <Image
                    src={data?.cover?.img346x247 || noImage}
                    sx={{
                        borderRadius: '9px',
                        objectFit: 'cover',
                        maxWidth: '520px',
                        maxHeight: '290px',
                        height: '247px',
                        width: '100%',
                    }}
                />
            </Box>

            <Stack gap="8px">
                <Stack
                    direction="row"
                    gap="6px"
                    alignItems="center"
                    divider={
                        <Box
                            sx={{
                                backgroundColor: '#6B7B8A',
                                width: '4px',
                                height: '4px',
                                borderRadius: '50%',
                            }}
                        />
                    }
                >
                    <Typography
                        fontSize="13px"
                        lineHeight="18px"
                        fontWeight={400}
                        color="#6B7B8A"
                    >
                        {formatDate(data.start)}
                    </Typography>
                    <Typography
                        fontSize="13px"
                        lineHeight="18px"
                        fontWeight={400}
                        color="#6B7B8A"
                    >
                        {formatLocation({
                            city: data.address?.street,
                            state: data.address?.state,
                        })}
                    </Typography>
                </Stack>
                <Typography
                    fontSize="17px"
                    lineHeight="21px"
                    fontWeight={600}
                    color="#131313"
                >
                    {data?.name || 'No title'}
                </Typography>
            </Stack>
            <Stack direction="row" gap="8px" flexWrap="wrap">
                {data.interests
                    ?.filter((_, i) => i < 2)
                    .map(i => (
                        <Tag key={i?.key}>{i?.val as string}</Tag>
                    ))}
            </Stack>
        </Stack>
    )
}

export default EventCard
