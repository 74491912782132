import React, { lazy } from 'react'

const HomePage = lazy(
    () =>
        import(
            /* webpackChunkName: "HomePage" */ 'components/pages/user/HomePage'
        )
)

const ProfilePage = lazy(
    () =>
        import(
            /* webpackChunkName: "ProfilePage" */ 'components/pages/user/ProfilePage'
        )
)

const PhotosPage = lazy(
    () =>
        import(
            /* webpackChunkName: "PhotosPage" */ 'components/pages/user/PhotosPage'
        )
)

const ClubsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ClubsPage" */ 'components/pages/user/ClubsPage'
        )
)

const UsersPage = lazy(
    () =>
        import(
            /* webpackChunkName: "UsersPage" */ 'components/pages/user/UsersPage'
        )
)

const CreateClubPage = lazy(
    () =>
        import(
            /* webpackChunkName: "CreateClubPage" */ 'components/pages/user/CreateClubPage'
        )
)

const ClubPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ClubPage" */ 'components/pages/user/ClubPage'
        )
)

const EventsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "EventsPage" */ 'components/pages/user/EventsPage'
        )
)

const EventPage = lazy(
    () =>
        import(
            /* webpackChunkName: "EventPage" */ 'components/pages/user/EventPage'
        )
)

const CreateEventPage = lazy(
    () =>
        import(
            /* webpackChunkName: "CreateEventPage" */ 'components/pages/user/CreateEventPage'
        )
)

const ApplicationsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ApplicationsPage" */ 'components/pages/user/ApplicationsPage'
        )
)

const ChatPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ChatPage" */ 'components/pages/user/ChatPage'
        )
)

const SettingsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "SettingsPage" */ 'components/pages/user/SettingsPage'
        )
)

const userPaths = {
    home: '/',
    profile: '/profile',
    photos: (id: string) => `/photos/${id}`,
    postSignUp: '/post-sign-up',
    events: '/events',
    event: (id: string) => `/events/${id}`,
    createEvent: '/events/create',
    clubs: '/clubs',
    users: '/users',
    applications: '/applications',
    createClub: '/clubs/create',
    club: (id: string) => `/clubs/${id}`,
    chat: '/chat',
    settings: '/settings',
}

const userRoutes = [
    {
        path: userPaths.home,
        element: <HomePage />,
        name: 'homepage',
        restrict: ['User'],
    },
    {
        path: userPaths.profile,
        element: <ProfilePage />,
        name: 'homepage',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.users,
        element: <UsersPage />,
        name: 'users',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.photos(':userId'),
        element: <PhotosPage />,
        name: 'photos',
        restrict: ['User'],
    },
    {
        path: userPaths.events,
        element: <EventsPage />,
        name: 'event',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.event(':eventId'),
        element: <EventPage />,
        name: 'event',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.createEvent,
        element: <CreateEventPage />,
        name: 'create event',
        restrict: ['User'],
    },
    {
        path: userPaths.clubs,
        element: <ClubsPage />,
        name: 'clubs',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.club(':clubId'),
        element: <ClubPage />,
        name: 'club',
        restrict: ['User', 'Admin'],
    },
    {
        path: userPaths.createClub,
        element: <CreateClubPage />,
        name: 'create club',
        restrict: ['User'],
    },
    {
        path: userPaths.applications,
        element: <ApplicationsPage />,
        name: 'applications',
        restrict: ['User'],
    },
    {
        path: userPaths.chat,
        element: <ChatPage />,
        name: 'chat',
        restrict: ['User'],
    },
    {
        path: userPaths.settings,
        element: <SettingsPage />,
        name: 'settings',
        restrict: ['User'],
    },
]

export { userPaths, userRoutes }
