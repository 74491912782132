import { object, string, number } from 'yup'

export const PostSignUpFormInitialValues = {
    school: '',
    location: null,
    grade: null,
}

export const PostSignUpFormScheme = object({
    school: string().required('School is required'),
    location: number().required('Location is required'),
    grade: number()
        .min(1, 'Should be higher than or equal to 1')
        .max(5, 'Should be less than or equal to 5')
        .required('Your should specify your grade'),
})
