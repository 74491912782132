import {
    ApolloClient,
    ApolloLink,
    from,
    fromPromise,
    split,
    FetchResult,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
// eslint-disable-next-line import/no-unresolved,@typescript-eslint/ban-ts-comment
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws'

import { API_ROOT, SUBSCRIPTIONS_ROOT, GRAPHQL_PATH } from 'config/env.config'
import { TokenStorage } from 'services'
import { RefreshTokenDocument, RefreshTokenMutation } from 'api/generated'

const graphQlRoot = `${API_ROOT}${GRAPHQL_PATH}`
const wsRoot = `${SUBSCRIPTIONS_ROOT}`

const httpLink = createUploadLink({
    uri: graphQlRoot,
})

const ApiLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        credentials: 'include',
        headers: {
            ...headers,
            authorization: TokenStorage.isAuthenticated()
                ? TokenStorage.getAuthenticationHeader()
                : '',
        },
    }))

    return forward(operation)
})

const getNewTokenByRefreshToken = (refreshToken: string | null) => {
    return fromPromise(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client
            .mutate({
                mutation: RefreshTokenDocument,
                variables: { refreshToken },
            })
            .then((response: FetchResult<RefreshTokenMutation>) => {
                if (response?.data) {
                    const result = response.data.refreshToken
                    if (result) {
                        const { token, refreshToken: newRefreshToken } = result
                        if (token && newRefreshToken) {
                            TokenStorage.storeToken(token)
                            TokenStorage.storeRefreshToken(newRefreshToken)
                            return token
                        }
                    }
                }
                return null
            })
            .catch(error => {
                if (
                    error.message === 'Invalid refresh token' ||
                    error.message === 'Refresh token is expired'
                ) {
                    TokenStorage.clear()
                    window.location.href = window.location.origin
                }
            })
    )
}

// SUBSCRIPTIONS
// let shouldRefreshToken = false
// const wsClient = createClient({
//     url: wsRoot,
//     lazy: false,
//     connectionParams: async (): Promise<{ token: string | null }> => {
//         if (shouldRefreshToken) {
//             // refresh the token because it is no longer valid
//             await getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
//             // and reset the flag to avoid refreshing too many times
//             shouldRefreshToken = false
//         }
//         return { token: TokenStorage.getToken() }
//     },
//     on: {
//         closed: event => {
//             // if closed with the `4403: Forbidden` close event
//             // the client or the server is communicating that the token
//             // is no longer valid and should be therefore refreshed
//             const err = event as unknown as Record<string, string>
//             if (
//                 [
//                     'RuntimeError: Error decoding signature',
//                     'RuntimeError: Signature has expired',
//                 ].includes(err.message)
//             )
//                 shouldRefreshToken = true
//         },
//     },
// })
// const wsLink = new GraphQLWsLink(wsClient)

export const wsClient = new SubscriptionClient(wsRoot, {
    reconnect: true,
    // lazy: true,
    minTimeout: 10000,
    connectionParams: async () => ({ token: TokenStorage.getToken() }),
    connectionCallback: (e: any) => {
        if (e?.message.includes('DecodeError: Invalid token type'))
            wsClient.close()
    },
})

wsClient.on('disconnected', async () => {
    wsClient.unsubscribeAll()
    const refreshToken = TokenStorage.getRefreshToken()
    if (refreshToken) await getNewTokenByRefreshToken(refreshToken)
})

const wsLink = new WebSocketLink(wsClient)

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message)
        if (
            messages.includes('Signature has expired') ||
            messages.includes('Error decoding signature')
        ) {
            return getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
                .filter(value => Boolean(value))
                .flatMap(newToken => {
                    const oldHeaders = operation.getContext().headers
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `JWT ${newToken}`,
                        },
                    })
                    return forward(operation)
                })
        }
    }
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    ApiLink.concat(httpLink)
)

const client = new ApolloClient({
    cache: new InMemoryCache({
        resultCaching: true,
        addTypename: true,
        typePolicies: {
            UserType: {
                fields: {
                    avatar: {
                        merge: true,
                    },
                },
            },
            ClubType: {
                fields: {
                    cover: {
                        merge: true,
                    },
                },
            },
            EventType: {
                fields: {
                    cover: {
                        merge: true,
                    },
                },
            },
        },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([errorLink, /* ApiLink.concat(httpLink) */ splitLink]),
})

export { client }
