import { SxProps, Typography } from '@mui/material'

interface TagProps {
    children: string
    sx?: SxProps
}

const Tag = ({ children, sx }: TagProps) => {
    return (
        <Typography
            sx={{
                backgroundColor: '#F3F4F6',
                borderRadius: '4px',
                color: '#4B5563',
                padding: '4px 6px',
                width: 'max-content',
                height: 'fit-content',
                ...sx,
            }}
            fontSize="12px"
            lineHeight="16px"
            fontWeight={500}
        >
            {children}
        </Typography>
    )
}

export default Tag
