import { forwardRef } from 'react'

import { SnackbarContent, useSnackbar } from 'notistack'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { format, formatDistance } from 'date-fns'
import { useMediaQuery } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import { NotificationType } from 'api/generated'
import { userPaths } from '../../../routes'

interface NotificationProps {
    data?: NotificationType
    listed: boolean
    onClose?: () => void
}

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
    ({ data, listed = false, onClose }: NotificationProps, ref) => {
        const navigate = useNavigate()
        const { closeSnackbar } = useSnackbar()

        const lessThan1100px = useMediaQuery('(max-width:1100px)')
        const lessThan700px = useMediaQuery('(max-width:700px)')
        const lessThan600px = useMediaQuery('(max-width:600px)')

        const type = data?.type
        const category = data?.category?.val

        const resolveDescription = () => {
            switch (type) {
                case 'invitation':
                    return (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            invited you to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>{' '}
                            with{' '}
                            <span style={{ color: '#1064E1' }}>
                                other {data?.relation?.membersQty} attendees
                            </span>
                            {category === 'Event' && (
                                <span>
                                    ,{' '}
                                    {data?.relation?.start &&
                                        format(
                                            new Date(data.relation.start),
                                            'mm/dd/yyyy & hh:mm a'
                                        )}
                                    , {data?.relation?.address}.
                                </span>
                            )}
                        </>
                    )
                case 'application':
                    return (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            has applied to join to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>{' '}
                            {category === 'Event' && (
                                <span>
                                    ,{' '}
                                    {data?.relation?.start &&
                                        format(
                                            new Date(data.relation.start),
                                            'mm/dd/yyyy & hh:mm a'
                                        )}
                                    , {data?.relation?.address}.
                                </span>
                            )}
                        </>
                    )
                case 'invitation_approved':
                case 'invitation_declined':
                    return category === 'Event' ? (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            {type === 'invitation_approved'
                                ? 'approved'
                                : 'declined'}{' '}
                            your invitation to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>{' '}
                            on
                            <span>
                                {data?.relation?.start &&
                                    format(
                                        new Date(data.relation.start),
                                        'mm/dd/yyyy & hh:mm a'
                                    )}
                                , on the {data?.relation?.address}.
                            </span>
                        </>
                    ) : (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            {type === 'invitation_approved'
                                ? 'approved'
                                : 'declined'}{' '}
                            your invitation to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>
                        </>
                    )
                case 'application_approved':
                case 'application_declined':
                    return category === 'Event' ? (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            {type === 'application_approved'
                                ? 'approved'
                                : 'declined'}{' '}
                            your application to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>{' '}
                            on
                            <span>
                                {data?.relation?.start &&
                                    format(
                                        new Date(data.relation.start),
                                        'mm/dd/yyyy & hh:mm a'
                                    )}
                                , on the {data?.relation?.address}.
                            </span>
                        </>
                    ) : (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            {type === 'application_approved'
                                ? 'approved'
                                : 'declined'}{' '}
                            your application to a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>
                        </>
                    )
                case 'role_update':
                    return (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            has updated your role in a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>
                        </>
                    )
                case 'member_remove':
                    return (
                        <>
                            <span style={{ color: '#1064E1' }}>
                                {data?.sender?.name}
                            </span>{' '}
                            has removed you from a{' '}
                            <span style={{ color: '#1064E1' }}>
                                {data?.relation?.name}
                            </span>
                        </>
                    )
                default:
                    return null
            }
        }

        return (
            <SnackbarContent
                ref={ref}
                style={{
                    position: 'relative',
                }}
            >
                <Stack
                    gap="16px"
                    width="100%"
                    sx={{
                        padding: listed ? '35px 24px 20px' : '35px 24px 20px',
                        borderRadius: '9px',
                        border: '1px solid #E5E7EB',
                        position: 'relative',
                        zIndex: 3,
                        overflow: 'hidden',
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 10px 3px rgb(3 30 38 / 18%)',
                        ...(lessThan1100px && {
                            padding: '35px 14px 14px',
                        }),
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '5px',
                            backgroundColor: `rgb(${Math.random() * 255}, ${
                                Math.random() * 255
                            }, ${Math.random() * 255})`,
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: '0',
                        }}
                    />

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        position="absolute"
                        width="100%"
                        sx={{
                            top: '10px',
                            left: 0,
                            right: 0,
                            padding: '0 14px 0 24px',
                            ...(lessThan1100px && {
                                padding: '0 14px 0 14px',
                            }),
                        }}
                    >
                        <Typography fontSize="13px" color="#707070">
                            {formatDistance(
                                new Date(data?.createdAt),
                                new Date(),
                                { addSuffix: true }
                            )}
                        </Typography>
                        {!listed && (
                            <IconButton
                                onClick={() => closeSnackbar('notification')}
                                sx={{
                                    padding: 0,
                                }}
                            >
                                <Close />
                            </IconButton>
                        )}
                    </Stack>
                    <Stack
                        direction={lessThan700px ? 'column-reverse' : 'row'}
                        alignItems={lessThan700px ? 'flex-start' : 'center'}
                        gap={lessThan700px ? '10px' : 0}
                        justifyContent="space-between"
                    >
                        <Box
                            sx={{
                                maxWidth: '450px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                ...(lessThan600px && {
                                    whiteSpace: 'wrap',
                                }),
                            }}
                        >
                            <Typography
                                fontSize="16px"
                                lineHeight="20px"
                                fontWeight={500}
                                color="#2C2626"
                                variant="caption"
                            >
                                {data?.type === 'chat_message'
                                    ? `${data.message} from ${data?.sender?.name}`
                                    : data?.message}
                            </Typography>
                        </Box>
                    </Stack>

                    <Typography
                        sx={{
                            color: '#1D1E20',
                            maxWidth: '600px',
                            fontSize: '14px',
                            lineHeight: '20px',
                            '& span': {
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#1D1E20',
                            },
                            ...(lessThan1100px && {
                                fontSize: '13px',
                                lineHeight: '18px',
                                '& span': {
                                    fontSize: '13px',
                                    lineHeight: '18px',
                                },
                            }),
                        }}
                    >
                        {resolveDescription()}
                    </Typography>

                    {(type === 'invitation' || type === 'application') && (
                        <Button
                            sx={{
                                cursor: 'pointer',
                                ...(!listed && {
                                    alignSelf: 'flex-end',
                                }),
                                ...(lessThan700px && {
                                    fontSize: '12px',
                                    alignSelf: 'flex-end',
                                }),
                            }}
                            onClick={() => {
                                onClose?.()
                                if (type === 'invitation') {
                                    navigate(
                                        `../${
                                            category === 'Club'
                                                ? userPaths.club(
                                                      data?.relation
                                                          ?.id as string
                                                  )
                                                : userPaths.event(
                                                      data?.relation
                                                          ?.id as string
                                                  )
                                        }?invited=true`
                                    )
                                } else {
                                    navigate(
                                        `../${
                                            userPaths.applications
                                        }?type=${category?.toLowerCase()}&id=${
                                            data?.relation?.id
                                        }`
                                    )
                                }
                                closeSnackbar('notification')
                            }}
                        >
                            View Details
                        </Button>
                    )}
                </Stack>
            </SnackbarContent>
        )
    }
)

export default Notification
