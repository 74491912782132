import { useState } from 'react'

import { Box, Checkbox, Stack, Typography } from '@mui/material'

import { Image, Tag } from 'components/common'
import noImage from 'assets/images/event-no-image.jpg'
import { ClubType, UserType } from 'api/generated'
import checkbox from '../../../assets/images/checkbox.svg'
import checkboxChecked from '../../../assets/images/checkbox-checked.svg'
import { getFullName } from 'utils/getFullname'

interface UserCardProps {
    onClick: (id: string) => void
    data: ClubType
    onSelect?: (id: string, selected: boolean) => void
    isSelected?: boolean
}

const ClubCard = ({
    onClick,
    data,
    onSelect,
    isSelected = false,
}: UserCardProps) => {
    const renderMembers = (data: ClubType) => {
        const members = data.members
            ?.map(m => m?.user)
            .filter(m => m?.id !== data.owner?.id) as UserType[]

        return (
            <>
                <Typography
                    key={data.owner?.id}
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight={400}
                    color="primary"
                    noWrap
                >
                    {getFullName(data.owner?.firstName, data.owner?.lastName)}
                    {members.length > 0 && ', '}
                </Typography>
                <Typography
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight={400}
                    color="#6B7B8A"
                    noWrap
                    maxWidth="200px"
                >
                    {members
                        .map(m => getFullName(m?.firstName, m?.lastName))
                        .join(', ')}
                </Typography>
            </>
        )
    }

    return (
        <Stack
            gap="16px"
            sx={{
                width: '100%',
                padding: '12px',
                borderRadius: '9px',
                border: '1px solid #E5E7EB',
                cursor: 'pointer',
                '&:hover': {
                    border: '1px solid #8c8c8c',
                },
            }}
            onClick={() => onClick(data.id)}
        >
            <Box
                sx={{
                    position: 'relative',
                    maxWidth: '520px',
                    maxHeight: '290px',
                    overflow: 'hidden',
                }}
            >
                <Image
                    src={data?.cover?.img346x247 || noImage}
                    sx={{
                        borderRadius: '9px',
                        objectFit: 'cover',
                        maxWidth: '520px',
                        maxHeight: '290px',
                        height: '247px',
                        width: '100%',
                    }}
                />
                {onSelect && (
                    <Checkbox
                        checked={isSelected}
                        icon={<Image src={checkbox} />}
                        checkedIcon={<Image src={checkboxChecked} />}
                        onChange={(_, checked) => {
                            onSelect?.(data.id, checked)
                        }}
                        onClick={e => e.stopPropagation()}
                        sx={{
                            position: 'absolute',
                            top: '8px',
                            left: '8px',
                            padding: '5px',
                            '& > img': {
                                width: '28px',
                                height: '28px',
                            },
                        }}
                    />
                )}
            </Box>

            <Typography
                fontSize="17px"
                lineHeight="21px"
                fontWeight={600}
                color="#131313"
            >
                {data?.name || 'No title'}
            </Typography>
            <Stack
                gap="2px"
                padding="15px 12px"
                sx={{ backgroundColor: '#F3F4F6', borderRadius: '9px' }}
            >
                <Typography
                    fontSize="13px"
                    lineHeight="17px"
                    fontWeight={500}
                    color="#23313B"
                    variant="caption"
                >
                    {data.members?.length === 1
                        ? `1 member`
                        : `${data.members?.length} members`}
                </Typography>
                <Stack gap="5px" direction="row">
                    {renderMembers(data)}
                </Stack>
            </Stack>
            <Stack direction="row" gap="8px" flexWrap="wrap">
                {data.interests
                    ?.filter((_, i) => i < 2)
                    .map(i => (
                        <Tag key={i?.key}>{i?.val as string}</Tag>
                    ))}
            </Stack>
        </Stack>
    )
}

export default ClubCard
