import { array, number, object, string } from 'yup'

import { AddressType } from 'api/generated'

export interface ClubInformationFormValues {
    name: string
    description: string
    interests: number[]
    address: AddressType | null
    gradeFrom: number | null
    gradeTo: number | null
    preview?: string
}

export const ClubInformationFormInitialValues = {
    name: '',
    description: '',
    interests: [],
    address: null,
    gradeFrom: null,
    gradeTo: null,
    preview: '',
}

export const ClubInformationFormScheme = object({
    name: string().required('Enter a name'),
    description: string()
        .max(1000, 'Max characters limit is exceeded')
        .required('Enter a description'),
    interests: array(number())
        .min(1, 'Your should specify at least 1 interest')
        .required('Your should specify your interests'),
    address: object({
        street: string().required('no street'),
        city: string().required(),
        state: string().required(),
        zipCode: string().required(),
        placeId: string().required(),
        location: object({
            lat: string().required(),
            lng: string().required(),
        }),
    }).required('Location is required field'),
    gradeFrom: number()
        .min(1, 'Should be higher than or equal to 1')
        .max(4, 'Should be less than or equal to 4')
        .test(
            'grade_correct',
            'Grade From must be lower than Grade To',
            function (value) {
                const { gradeTo } = this.parent

                return gradeTo > (value || 0)
            }
        )
        .required('Your should specify minimal grade'),
    gradeTo: number()
        .min(2, 'Should be higher than or equal to 2')
        .max(5, 'Should be less than or equal to 5')
        .test(
            'grade_correct',
            'Grade To must be higher than Grade From',
            function (value) {
                const { gradeFrom } = this.parent

                return gradeFrom < (value || 0)
            }
        )
        .required('Your should specify maximal grade'),
})
