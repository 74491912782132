import { useEffect, useState } from 'react'

import {
    FetchResult,
    useLazyQuery,
    useMutation,
    useQuery,
} from '@apollo/client'

import {
    ClubDetailsDocument,
    ClubListDocument,
    ClubType,
    ClubUpdateMutation,
    MutationClubUpdateArgs,
    ClubUpdateDocument,
    ClubCreateMutation,
    MutationClubCreateArgs,
    ClubCreateDocument,
    ClubListQueryVariables,
} from 'api/generated'

export const useClub = (id: string, options = {}) => {
    const { data, loading, error, refetch, networkStatus } = useQuery(
        ClubDetailsDocument,
        {
            variables: { id },
            ...options,
        }
    )

    let club: ClubType | undefined

    if (data?.clubDetails) {
        club = data.clubDetails as ClubType
    }

    return {
        club,
        loading,
        error,
        refetch,
    }
}

export const useLazyClubDetails = () => {
    const [getClub, { data, loading, error, refetch }] =
        useLazyQuery(ClubDetailsDocument)

    let club: ClubType | undefined

    if (data?.clubDetails) {
        club = data.clubDetails as ClubType
    }

    return {
        getClub,
        club,
        loading,
        error,
        refetch,
    }
}

export const useClubs = (
    variables: ClubListQueryVariables = {},
    options = {}
) => {
    const [response, setResponse] = useState<ClubType[]>([])
    const [total, setTotal] = useState(0)

    const { data, loading, error, refetch } = useQuery(ClubListDocument, {
        variables,
        ...options,
    })

    useEffect(() => {
        if (data?.clubList) {
            const total = data?.clubList.totalCount
            const clubs: ClubType[] = data?.clubList.edges.map(
                edge => edge?.node as ClubType
            )
            setResponse(clubs)
            setTotal(total as number)
        }
    }, [data])

    return {
        clubs: response,
        total,
        loading,
        error,
        refetch,
    }
}

export const useClubAPI = () => {
    const [updateClub] = useMutation(ClubUpdateDocument)
    const [createClub, { loading }] = useMutation(ClubCreateDocument)

    const update = (data: MutationClubUpdateArgs) => {
        return updateClub({ variables: data }).then(
            (response: FetchResult<ClubUpdateMutation>) =>
                response?.data?.clubUpdate
        )
    }

    const create = (data: MutationClubCreateArgs) => {
        return createClub({ variables: data }).then(
            (response: FetchResult<ClubCreateMutation>) =>
                response?.data?.clubCreate
        )
    }

    const open = (id: string) => {
        return update({
            input: {
                id,
                status: 1,
            },
        })
    }

    const close = (id: string) => {
        return update({
            input: {
                id,
                status: 2,
            },
        })
    }

    return {
        update,
        create,
        open,
        close,
        loading,
    }
}
