import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    EventCreateDocument,
    EventCreateMutation,
    EventDetailsDocument,
    EventListDocument,
    EventListQueryVariables,
    EventType,
    MutationEventCreateArgs,
} from 'api/generated'

export const useEvent = (id: string, options = {}) => {
    const { data, loading, error, refetch } = useQuery(EventDetailsDocument, {
        variables: {
            id,
        },
        ...options,
    })

    let event: EventType | undefined

    if (data?.eventDetails) {
        event = data.eventDetails as EventType
    }

    return {
        event,
        loading,
        error,
        refetch,
    }
}

export const useEvents = (
    variables: EventListQueryVariables = {},
    options = {}
) => {
    const [response, setResponse] = useState<EventType[]>([])
    const [total, setTotal] = useState(0)

    const { data, loading, error, refetch } = useQuery(EventListDocument, {
        variables,
        ...options,
    })

    useEffect(() => {
        if (data?.eventList) {
            const total = data?.eventList.totalCount
            const events: EventType[] = data?.eventList.edges.map(
                edge => edge?.node as EventType
            )
            setResponse(events)
            setTotal(total as number)
        }
    }, [data])

    return {
        events: response,
        total,
        loading,
        error,
        refetch,
    }
}

export const useEventAPI = () => {
    const [createClub, { loading }] = useMutation(EventCreateDocument)

    const create = (data: MutationEventCreateArgs) => {
        return createClub({ variables: data }).then(
            (response: FetchResult<EventCreateMutation>) =>
                response?.data?.eventCreate
        )
    }

    return {
        create,
        loading,
    }
}
