import React from 'react'

import { Stack, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { NotificationsNone } from '@mui/icons-material'

import { NotificationType } from 'api/generated'
import { Notification } from 'components/common'

interface NotificationsLayoutProps {
    items: NotificationType[]
    onClose: () => void
}

const NotificationsLayout = ({ items, onClose }: NotificationsLayoutProps) => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const renderEmptyList = () => {
        return (
            <Stack
                justifyContent="space-between"
                alignItems="center"
                padding="50px 0"
                gap="5px"
            >
                <NotificationsNone
                    sx={{
                        fontSize: '38px',
                        color: '#9d9d9d',
                    }}
                />
                <Typography color="#74747B">
                    You have no notifications yet
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            padding="24px"
            gap="12px"
            minWidth={lessThan700px ? '0' : '650px'}
        >
            {items.length === 0
                ? renderEmptyList()
                : items.map(item => (
                      <Notification data={item} listed onClose={onClose} />
                  ))}
        </Stack>
    )
}

export default NotificationsLayout
